body { margin: 0; }


.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.login img {
  display: block;
  width: 175px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
.login-item {
  width: 350px;
}
.login input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
@media (max-width: 576px) {
  .login {
      align-items: flex-start; /* Make room for the mobile keyboard and password mangers, so as not to block "Sign in" button.  In the future, maybe we can have it centered but move it to flex-start on focus. */
  }
  .login input {
      font-size: 16px; /* Font size must be 16px to prevent iOS zooming-in on input focus */
  }
}
.login p {
  text-align: center;
}


.navbar div {
  position: absolute;
  top: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  border: solid 1px lightgray;
}
.navbar ul li {
  list-style: none;
  display: inline-block;
  margin: 2px;
}
.navbar i {
  color: cornflowerblue;
  margin: 3px;
}
.navbar a {
  color: #333;
  text-decoration: none;
}
.navbar a:hover {
  text-decoration: underline
}
.navbar.active {
  text-decoration: underline;
}

.learn-rendering {
  border: solid black 1px;
  border-radius: 40px;
  height: 700px;
  width: 350px;
  margin: 50px auto auto;
  overflow: hidden;
  position: relative;
}


.assessment-flex {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
}
.assessment-content {
    font-size: 24px;
    padding: 25px;
    width: 100%
}
.assessment-content input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    box-sizing: border-box;
}
.assessment-content input[type=submit] {
    cursor: pointer;
}

.written-content {
  padding: 20px;
  overflow-wrap: anywhere;
  /* font-size: ${content.textSize}px;
  color: ${content.textColor}; */
  text-align: center;
}
.written-content p {
  cursor: pointer;
}
.written-content input {
  cursor: pointer;
}

.video-content {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  object-fit: cover; /* <- Not widely supported in browsers, may need polyfill */
  cursor: pointer;
}



/* CourseEdit */

#course-editor {
  position: relative; /* Non-statically positioned so the exit button can be positioned absolutely in the top right. */
}

.content-editor {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
.content-rendering {
  border: 1px solid;
  border-radius: 40px;
  height: 700px; /* Ratio should actually be 16:9, or 4^2:3^2 */
  width: 350px;
  margin-left: 200px;
  background-color: #FAFAFA;
}

.content-rendering p {
  color: #1C1C1C;
}

.content-options {
  width: 180px;
  margin-left: 20px;
}
.content-options input {
  display: block;
  margin-bottom: 10px;
}

.content-options input[type=text] {
  width: 200px;
  padding: 10px;
}

.content-options input[type=number] {
  width: 200px;
  padding: 10px;
}

.content-options textarea {
  width: 200px;
  height: 120px;
  margin-bottom: 10px;
}

.preview-text {
  padding: 20px;
  overflow-wrap: anywhere;
  text-align: center;
}

.content-editor-chooser {
  margin: auto;
  width: 350px;
  text-align: center;
}

.content-editor-chooser button {
  margin: auto;
  display: block;
}

.content-editor-add-content {
  margin: auto;
  width: 350px;
}

.content-editor-add-content button {
  margin: auto;
  display: block;
}

.course-editor-exit {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-family: monospace, "Courier New";
}
.course-editor-header {
  display: block;
  margin: auto;
  width: 350px;
}
.course-editor-header input {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: 350px
}

/* CourseQueue */

.course-queue {
  position: absolute;
  top: 20px;
  left: 0;
}
.course-queue ol {
  list-style: none;
  margin: 2px;
  padding: 0;
}
.course-queue li:hover {
  text-decoration: underline;
  cursor: pointer;
}
.course-queue input {
  width: 225px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}
.course-queue i {
  z-index: 1;
  position: relative;
  right: 25px;
  color: grey;
  top: calc(50% - 0.5em); /* Keep icon in center of input, regardless of the input height */
}
